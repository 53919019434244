body {
  margin: 0;
  padding: 0;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

.score {
  pointer-events: none;
  padding: 32px 0;
  color: #0004;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    sans-serif;
  font-size: 32px;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100vw;
  z-index: 100000;
}
